<template>
  <div>
    <my-alert
      :show="message !== '' && message !== null"
      :dismissible="true"
      class="vue--alert"
      variant="danger"
      @dismissed="clearError"
    >
      <p v-html="message"></p>
    </my-alert>
  </div>
</template>

<script>
const MyAlert = () => import('@/components/utils/my-alert.vue');

export default {
  components: {
    MyAlert,
  },
  computed: {
    singleErrors() {
      const raw = this.$store.state.error.validationErrors;
      if (raw) {
        let temp;
        temp = Object.keys(raw).map(function (key) {
          return raw[key][0];
        });
        return temp;
      } else {
        return null;
      }
    },
  },
  watch: {
    singleErrors(value) {
      if (value) {
        this.singleErrors.forEach((err) => {
          this.message = `${this.message}• ${err}</br>`;
        });
      }
    },
  },
  data: () => ({
    message: '',
  }),
  methods: {
    clearError() {
      this.message = '';
      this.$store.commit('error/CLEAR_ERROR');
    },
  },
};
</script>

<style lang="scss" scoped>
.vue--alert {
  position: fixed;
  right: 2em;
  top: 2em;
  padding: 10px 50px 10px 12px;
  z-index: 2000;
  @media only screen and (max-width: 767px) {
    right: 0;
    top: 0;
    left: 0;
  }
  p {
    margin: 0;
  }
}
</style>
